/* eslint-disable */
import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";
import { redirectToCheckout } from "../../utils/stripe";
import { getToken } from "../../utils/auth";
import { brand, images } from "../../utils/assets";
import logo from "../../images/NOBS_LOGO_NAVYBLUE.svg";

// import Loader from "react-loader-spinner";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import { faChevronRight } from "@fortawesome/fontawesome-svg-core";
// import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import RoundedContainer from "../../components/RoundedContainer";
import SectionHeader from "../../components/SectionHeader";
import SubscribeCard from "../../components/SubscribeCard";
import { theme } from "../../theme";

import { MONTHLY, QUARTERLY, YEARLY, PRICES } from "../../utils/constants";

const isBrowser = typeof window !== `undefined`;

const SubscriptionPage = () => {
  const [state, setState] = React.useState({
    isAuthenticated: false,
  });

  React.useEffect(() => {
    checkAuthenticated();
  }, []);

  const checkAuthenticated = async () => {
    try {
      await getToken();
      setState({ ...state, isAuthenticated: true });
    } catch (e) {
      setState({ ...state, isAuthenticated: false });
    }
  };

  return (
    <Layout>
      <SEO title="Subscribe" />
      <Container fluid className="subscribe-page section bg-gradient">
        {!state.isAuthenticated && (
          <div className="text-center">
            <img src={logo} className="subscription-logo" alt={brand.NAME} />
            <p>
              Please{" "}
              <Link to="/join" className="text-btn">
                login or register
              </Link>
            </p>
          </div>
        )}
        {state.isAuthenticated && (
          <>
            <div className="subscription-options">
              <RoundedContainer>
                <SectionHeader
                  title="Pick your subscription"
                  subtitle={["Join today, cancel what you want"]}
                />
                <div className="subscribe__container">
                  <SubscribeCard
                    frequency="monthly"
                    price={PRICES.originalMonthly}
                    pricePerDay={PRICES.monthlyPerDay}
                    originalPrice={PRICES.originalMonthly}
                    onClickAction={() => {
                      return isBrowser && onSelectSubscription(MONTHLY);
                    }}
                  />
                  <SubscribeCard
                    frequency="quarterly"
                    price={PRICES.originalQuarterly}
                    pricePerDay={PRICES.quarterlyPerDay}
                    originalPrice={PRICES.originalQuarterly}
                    bg={theme.colors.lilac}
                    onClickAction={() => {
                      return isBrowser && onSelectSubscription(QUARTERLY);
                    }}
                  >
                    <img
                      src={images.SAVE10}
                      className="subscribeCard__image"
                      alt="Save 10%"
                    />
                  </SubscribeCard>

                  <SubscribeCard
                    frequency="yearly"
                    price={PRICES.originalAnnually}
                    originalPrice={PRICES.originalAnnually}
                    pricePerDay={PRICES.annuallyPerDay}
                    onClickAction={() => {
                      return isBrowser && onSelectSubscription(YEARLY);
                    }}
                  >
                    <img
                      src={images.SAVE15}
                      className="subscribeCard__image--mid"
                      alt="Save 15%"
                    />
                  </SubscribeCard>
                </div>
              </RoundedContainer>
            </div>

            {/*


monthly: 11.99
quarterly: 31.99
Annual: 119.99
---
annually: £0.33 per day
quarterly: £0.36 per day
mothly: £0.40 per day
            <Row className="subscription-row">
              <Col>
                <a
                  onClick={() => {
                    isBrowser && onSelectSubscription("annually");
                  }}
                  href="#"
                >
                  <div className="subscription-box shadow">
                    <div className="save-tag"><p>Save 33%</p></div>
                    <div className="box-inner">
                      <h4>Annually</h4>
                      <h2>£79.99 <span className="small">/ year</span></h2>
                      <p>£0.21 per day</p>
                      <p className="start">Start now <FontAwesomeIcon icon={faChevronRight} size="1x" /></p>
                    </div>
                  </div>
                </a>
              </Col>
              <Col>
                <a
                  onClick={() => {
                    isBrowser && onSelectSubscription("quarterly");
                  }}
                  href="#"
                >
                  <div className="subscription-box shadow">
                    <div className="save-tag"><p>Save 17%</p></div>
                    <div className="box-inner">
                      <h4>Quarterly</h4>
                      <h2>£24.99 <span className="small">/ quarter</span></h2>
                      <p>£0.27 per day</p>
                      <p className="start">Start now <FontAwesomeIcon icon={faChevronRight} size="1x" /></p>
                    </div>
                  </div>
                </a>
              </Col>
              <Col>
                <a
                  onClick={() => {
                    isBrowser && onSelectSubscription("monthly");
                  }}
                  href="#"
                >
                  <div className="subscription-box shadow">
                    <div className="box-inner">
                      <h4>Monthly</h4>
                      <h2>£9.99 <span className="small">/ monthly</span></h2>
                      <p>£0.33 per day</p>
                      <p className="start">Start now <FontAwesomeIcon icon={faChevronRight} size="1x" /></p>
                    </div>
                  </div>
                </a>
              </Col>

            </Row> */}

            <Row className="info-row">
              <Col className="text-center" lg={12}>
                {" "}
                <p className="small">
                  By continuing you accept our{" "}
                  <Link className="link-btn" to="/privacy-policy">
                    privacy policy
                  </Link>{" "}
                  and{" "}
                  <Link className="link-btn" to="/terms-and-conditions">
                    terms and conditions
                  </Link>
                  . In agreeing terms and conditions for (nobs) we will create
                  you an account that will allow you to access our content on
                  most iOS and Android devices.
                </p>
                <p>
                  Need help? Visit{" "}
                  <a
                    className="link-btn"
                    target="_blank"
                    href="https://support.nobsapp.co.uk/hc/en-us"
                  >
                    Support Center
                  </a>
                </p>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Layout>
  );
};

const onSelectSubscription = (selectedPlan) => {
  try {
    redirectToCheckout(selectedPlan);
  } catch (e) {
    console.log(e);
  }
};

export default SubscriptionPage;
