/* eslint-disable */
import axios from 'axios';

import {getToken} from './auth';

const stripeToken = process.env.STRIPE_KEY;
const baseSessionUrl = `${process.env.SUBSCRIPTIONS_BASE_URL}session`;
const getSubscriptionsEndpoint = `${process.env.SUBSCRIPTIONS_BASE_URL}subscriptions`;
const cancelSubscriptionsEndpoint = `${process.env.SUBSCRIPTIONS_BASE_URL}subscriptions`;


const isBrowser = typeof window !== `undefined`;

const getSession = (plan, IdToken) => axios.get(`${baseSessionUrl}/${plan}`,{
    timeout: 10000,
    headers: {'Authorization': `Bearer ${IdToken}`}
});

export const redirectToCheckout = async (uri) => {
    if(isBrowser){
        try{
            console.log(uri);
            const IdToken = await getToken();
            const data = await getSession(uri, IdToken.idToken.jwtToken);
            console.log(data.data.sessionId);
            const stripe = await window.Stripe(stripeToken).redirectToCheckout({sessionId: data.data.sessionId});
        }catch(e){
            console.log(e);
        }
    }
};

export const getSubscription = async () =>{

    const IdToken = await getToken();

    console.log(getSubscriptionsEndpoint);

    return axios.get(getSubscriptionsEndpoint,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    });
};

export const cancelSubscription = async (subscriptionId) => {
    const IdToken = await getToken();

    return axios.get(`${cancelSubscriptionsEndpoint}/${subscriptionId}/cancel`,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    });
};