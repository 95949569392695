import LOGO from "../images/NOBS_LOGO_NAVYBLUE.svg";
import APP_LOGO from "../images/NOBS_MONOGRAM_COLORS.svg";
import CART from "../images/bag.svg";
import DARK_LOGO from "../images/dark-icon.png";

import recipe1 from "../images/recipe-1.jpg";
import recipe2 from "../images/recipe-2.jpg";
import recipe3 from "../images/recipe-3.jpg";
import recipe4 from "../images/recipe-4.jpg";

import SMILEY1 from "../images/Website-Smiley3.png";
import SMILEY2 from "../images/Website-Smiley2.png";
import SMILEY3 from "../images/Website-Smiley.png";
import WORKOUTS from "../images/Website-WorkoutScreen.png";
import RECIPIES from "../images/Website-RecipeBookScreen.png";
import PEP_TALKS from "../images/Website-DailyPepTalkScreen.png";
import CROSS from "../images/Not_done.svg";
import TICK from "../images/Tick.svg";
import SAVE10 from "../images/Website-PricingSection-OfferBadge-Text-Quarterly.png";
import SAVE15 from "../images/Website-PricingSection-OfferBadge-Text-Annualy.png";
import MENU from "../images/Burger_Menu.svg";
import APP_STORE from "../images/badge-1.png";
import GOOGLE_PLAY from "../images/badge-2.png";
import TWITTER from "../images/Twitter.svg";
import LINKEDIN from "../images/LinkedIn.svg";
import YOUTUBE from "../images/Youtube.svg";
import BANNER from "../videos/NobsApp-WebsiteBannerLoop-V2.mp4";
import GROUP_BANNER from "../images/MainGroupShot-Banner.jpg";
 
import FOUNDER_BADGE from "../images/FoundingMemberBadge.png";
import FLAG01 from "../images/flag-01.svg";
import FLAG02 from "../images/flag-02.svg";
import FLAG03 from "../images/flag-03.svg";
import SAVE30 from "../images/save30.png";
import SAVE35 from "../images/save35.png";
import INSTA from "../images/Instagram.svg";
import FACEBOOK from "../images/FB.svg";
import LUCY from "../images/Webside-LucyPink.jpg";

export const links = {
  APP_STORE: "https://apps.apple.com/gb/app/nobs/id1559964990",
  GOOGLE_PLAY: "https://play.google.com/store/apps/details?id=com.mountain.nobs",
  FACEBOOK:"",
  TWITTER:"",
  YOUTUBE: "https://www.youtube.com/channel/UCjwZRU_NaG0bMEwXhc68izQ",
  INSTAGRAM: "https://www.instagram.com/lucymountain/",
};

export const slides = [
  {
  
    description: {
      title: "Deepa",
      subTitle: "@dxlmoves",
      body:
        "Fitness is freedom from chaos. Rolls are beautiful. Don't forget to love you, for you.",
    },
  },
  {
   
    description: {
      title: "Queenie",
      subTitle: "@whatwouldqueeniedo",
      body:
        "Fitness is what makes you happy regardless of how you want to move it.",
    },
  },
  {
    
    description: {
      title: "Michelle",
      subTitle: "@chellesday",
      body:
        "Fitness is hitting a PB in the gym and moving in a way that works best for my mood.",
    },
  },
  {
   
    description: {
      title: "Paris",
      subTitle: "@parisamy_",
      body: "Fitness is moving my body to feel good.",
    },
  },
  {
   
    description: {
      title: "Tiffany",
      subTitle: "@tiffanypric.e",
      body:
        "Fitness is something to enjoy. And food is to be eaten without guilt.",
    },
  },
];

export const brand = {
  NAME: "Nobs App",
  LOGO: LOGO,
  APP_LOGO: APP_LOGO,
  DARK_LOGO: DARK_LOGO,
};

export const icons = {
  CART: CART,
  CROSS: CROSS,
  TICK: TICK,
  APP_STORE: APP_STORE,
  GOOGLE_PLAY: GOOGLE_PLAY,
  MENU: MENU,
  YOUTUBE: YOUTUBE,
  LINKEDIN: LINKEDIN,
  TWITTER: TWITTER,
  FACEBOOK: FACEBOOK,
  INSTAGRAM: INSTA,
};

export const images = {
  LUCY: LUCY,
  HERO: GROUP_BANNER,
  WORKOUTS: WORKOUTS,
  SMILEY1: SMILEY1,
  SMILEY2: SMILEY2,
  SMILEY3: SMILEY3,
  RECIPIES: RECIPIES,
  PEP_TALKS: PEP_TALKS,
  SAVE10: SAVE10,
  SAVE15: SAVE15,
  FOUNDER_BADGE: FOUNDER_BADGE,
  FLAG01: FLAG01,
  FLAG02: FLAG02,
  FLAG03: FLAG03,
  SAVE30: SAVE30,
  SAVE35: SAVE35,
};

export const videos = {
  BANNER: BANNER,
};

export const recipieSlides = [recipe1, recipe2, recipe3, recipe4];
