export const theme = {
  colors: {
    white: "#fff",
    red: "#ff7f70",
    lilac: "#ccc7ff",
    lilacLight: "#f6f4fe",
    yellow: "#ffff80",
    blue: "#6680d4",
    pink: "#ffbab8",
    navy: "#343a64",
  },
};
