import { Link } from "gatsby";
import React from "react";
import "./style.scss";
// import Button from "react-bootstrap/Button";

const PrimaryButton = ({
  label,
  path,
  isFullWidth,
  bg,
  color,
  marginTop,
  onClickAction,
}) => {
  return (
    <>
      {path ? (
        <a href={path}>
          <button
            className="primary-button"
            style={{
              backgroundColor: bg,
              color: color,
              marginTop: marginTop,
              width: isFullWidth && "100%",
            }}
          >
            {label}
          </button>
        </a>
      ) : (
        <button
          className="primary-button"
          style={{
            backgroundColor: bg,
            color: color,
            marginTop: marginTop,
            width: isFullWidth && "100%",
          }}
          onClick={onClickAction}
        >
          {label}
        </button>
      )}
    </>
  );
};

export default PrimaryButton;
