import React from "react";
import { theme } from "../../theme";
import PrimaryButton from "../PrimaryButton";
import "./style.scss";

import { PRICES } from "../../utils/constants";

const { currency, currencyLocation } = PRICES;

const formatterCurrency = (value) =>
  new Intl.NumberFormat(currencyLocation, {
    style: "currency",
    currency,
  }).format(value);

const SubscribeCard = ({
  frequency,
  originalPrice,
  price,
  pricePerDay,
  bg,
  children,
  path,
  onClickAction,
}) => {
  return (
    <div
      className={`subscribeCard__container ${bg && "scale"}`}
      style={{ backgroundColor: bg }}
    >
      {/* {children} */}
      <h3>{frequency}</h3>
      <div className="subscribeCard__pricing">
        <div className="subscribeCard__priceContainer">
          <h3>{formatterCurrency(price)}</h3> <p>/ {frequency}</p>
        </div>
        <p>{formatterCurrency(pricePerDay)} per day</p>
        {/* <div className="subscribeCard__priceContainer">
          <div className="strikethrough" />
          <h3>{formatterCurrency(originalPrice)}</h3>
          <p>/ {frequency}</p>
        </div> */}
      </div>

      <p>billed {frequency}</p>
      <PrimaryButton
        marginTop="1.5rem"
        isFullWidth
        bg={theme.colors.navy}
        color={theme.colors.white}
        label="Choose Plan"
        path={path}
        onClickAction={onClickAction}
      />
    </div>
  );
};

export default SubscribeCard;
