import React from "react";
import "./style.scss";

const SectionHeader = ({ title, subtitle = [], textAlign }) => {
  return (
    <div className="section-header__container" style={{ textAlign: textAlign }}>
      <h1 className="section-header__title">{title}</h1>
      {subtitle.map((item, index) => (
        <p key={index} className="section-header__subtitle">
          {item}
        </p>
      ))}
    </div>
  );
};

export default SectionHeader;
