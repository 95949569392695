const vendorUUID = "b4456492-3a93-11e9-bfcd-0ac0203a1ad8";

const MONTHLY = "monthly";
const QUARTERLY = "quarterly";
const YEARLY = "annually";

const PRICES = {
  currencyLocation: "en-GB", // https://github.com/libyal/libfwnt/wiki/Language-Code-identifiers#0x0400---0x04ff
  currency: "GBP", // https://docs.1010data.com/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html
  monthly: 9.99,
  quarterly: 25.99,
  annual: 99.99,
  annuallyPerDay: 0.32,
  quarterlyPerDay: 0.35,
  monthlyPerDay: 0.4,
  originalMonthly: 11.99,
  originalQuarterly: 31.99,
  originalAnnually: 119.99,
};

const tintColor = "#2f95dc";

const COLOURS = {
  tintColor,
  tabIconDefault: "#ccc",
  tabIconSelected: tintColor,
  tabBar: "#fefefe",
  errorBackground: "red",
  errorText: "#fff",
  warningBackground: "#EAEB5E",
  warningText: "#666804",
  noticeBackground: tintColor,
  noticeText: "#fff",

  shreddyYellow: "#FFFA8A",
  shreddyPink: "#FCBCD8",
  shreddyBlue: "#B5E8FA",
  shreddyGreen: "#ACEABB",
  shreddyRed: "#ea4052",
};

const ALL = "all";
const BANDS = "short fabric band";
const HOME = "home range";
const LIFTING = "lifting range";
const BUNDLES = "bundle";
const FEATURED = "featured";
const NEW = "new";

module.exports = {
  vendorUUID,
  COLOURS,
  MONTHLY,
  QUARTERLY,
  YEARLY,
  PRICES,
  ALL: ALL,
  BANDS: BANDS,
  HOME: HOME,
  LIFTING: LIFTING,
  BUNDLES: BUNDLES,
  NEW: NEW,
  FEATURED: FEATURED,
  PRODUCT_CATALOG: [
    HOME,
    LIFTING,
    BUNDLES,
    // BANDS,
  ],
  // RECOMMENDED: {
  //     'boxing': BOXING,
  //     'longResistanceBand': LONG_BAND_RECOMMENDED,
  //     'shortResistanceBand': SHORT_BAND_RECOMMENDED,
  //     'sliderPack': HOME_RANGE_RECOMMENDED,
  //     'abWheel': HOME_RANGE_RECOMMENDED,
  //     'dumbbellPack': HOME_RANGE_RECOMMENDED,
  //     'foamRoller': HOME_RANGE_RECOMMENDED,
  //     'exerciseMat': HOME_RANGE_RECOMMENDED,
  // },
  // ENABLED_BANDS_STRENGTH_SPLIT: ['Light', 'Medium', 'Heavy', 'XHeavy', 'XXHeavy']
};
