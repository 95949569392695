import React from "react";
import "./style.scss";

const RoundedContainer = ({ bg, children }) => {
  return (
    <div style={{ backgroundColor: bg }} className="rounded-container">
      {children}
    </div>
  );
};

export default RoundedContainer;
